<template>
  <window-content class="business-invoice-container">
    <div class="no-pointer-events no-select font-12" v-if="loading">
      <div class="text-center flex flex-center m-b" style="height: 20px; width: 200px; margin: auto">
        <sl-loading loading-style="coffe" />
      </div>
      <div class="m-t">Aguarde enquanto buscamos dados da sua fatura</div>
    </div>
    <div v-else-if="isPago">
      <div class="business-invoice-paid">
        <div class="paid-icon text-center">
          <i class="fa fa-check-circle text-green" style="font-size: 32px"/>
        </div>
        <div class="text-center font-bold font-14 font-avenir m-t-sm">Fatura paga!</div>
        <div class="text-center font-14">Agradecemos pelo pagamento.</div>
        <div class="text-center font-12 m-t-sm">Protocolo da transação: <strong>{{invoice.transacaoId}}</strong></div>
      </div>
    </div>
    <div v-else>
      <div class="business-invoice">
        <div class="iline">
          <div class="ileft">Vencimento:</div>
          <div class="iright"><strong>{{invoice.dataVencimento|sdate}}</strong></div>
        </div>
        <div class="iline">
          <div class="ileft">Descrição:</div>
          <div class="iright"><strong>{{invoice.descricao}}</strong></div>
        </div>
        <div class="iline">
          <div class="ileft">Valor Total:</div>
          <div class="iright"><strong>R$ {{invoice.valor|moeda}}</strong></div>
        </div>
        <div class="iline">
          <div class="ileft">Transação ID:</div>
          <div class="iright">{{invoice.transacaoId}}</div>
        </div>
        <div class="iline">
          <div class="ileft">Competência:</div>
          <div class="iright">{{invoice.dataCompetencia|sdate}}</div>
        </div>
      </div>
      <div class="payment-methods m-t" v-if="!payResponse">
        <div>Escolha a forma de pagamento</div>
        <div class="methods" v-if="!payLoading">
          <a class="ipix" v-if="hasMethod('pix')" @click="pay('pix')">
            <div class="iico"><img src="../assets/logo-pix.png" /></div>
            <div class="ilegend">Pagar com PIX</div>
          </a>
          <a class="iboleto" v-if="hasMethod('boleto')" @click="pay('boleto')">
            <div class="iico"><img src="../assets/logo-boleto.svg" /></div>
            <div class="ilegend">Pagar com Boleto</div></a>
        </div>
        <div class="m-t" v-else>
          <sl-loading />
          <div class="m-t-xs">Aguarde, estamos comunicando com o banco...</div>
        </div>
      </div>
      <invoice-generator class="i-generator" :integrador="payResponse" v-else />
    </div>
  </window-content>
</template>

<script>
/* eslint-disable */
import {WindowContent} from "@/plugins/uloc-erp"
import {checkStatusInvoice, invoice, payInvoice} from "@/domain/business/services"
import {UFakeLoader} from "uloc-vue"
import SlLoading from "components/layout/components/Loading";
import InvoiceGenerator from "components/dashboard/components/Business/window/InvoiceGenerator";

export default {
  name: 'InvoiceWindow',
  props: ['id'],
  data () {
    return {
      loading: true,
      payLoading: false,
      payResponse: null,
      invoice: null,
      methods: null
    }
  },
  mounted () {
    this.load()
  },
  beforeDestroy() {
    this.invoiceCheckInterval && clearInterval(this.invoiceCheckInterval)
  },
  computed: {
    isPago () {
      return this.invoice && this.invoice.pago
    }
  },
  methods: {
    load () {
      this.loading = true
      invoice(this.id)
          .then(({data}) => {
            console.log(data)
            this.invoice = data.invoice
            this.methods = data.methods
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    hasMethod (m) {
      if (!this.methods || !this.methods.default) {
        return false
      }
      return this.methods.default.methods.includes(m)
    },
    pay (method) {
      this.payLoading = true
      payInvoice(this.invoice.id, this.methods.default.name, method)
          .then(response => {
            this.payLoading = false
            this.payResponse = response.data
            if (['pix', 'cartao'].includes(method)) {
              try {
                this.invoiceCheckInterval = setInterval(() => {
                  this.checkStatusInvoice()
                }, 10000)
              } catch (e) {
                console.error(e)
                clearInterval(this.invoiceCheckInterval)
              }
            }
            console.log(response)
          })
          .catch(error => {
            this.payLoading = false
            this.alertApiError(error)
          })
    },
    async checkStatusInvoice () {
      const response = await checkStatusInvoice(this.invoice.id)
      if (response.data && response.data.invoice && response.data.invoice.pago) {
        this.invoice.pago = true
        this.invoiceCheckInterval && clearInterval(this.invoiceCheckInterval)
      }
    }
  },
  watch: {},
  components:{
    InvoiceGenerator,
    SlLoading,
    WindowContent,
    UFakeLoader
  }
}
</script>

<style lang="stylus">
.erp-invoice-window {
  .u-window .u-window-content {
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, .024) 0, rgba(160, 51, 255, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, .04) 0, rgba(160, 51, 255, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, .064) 0, rgba(160, 51, 255, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .024) 0, rgba(24, 119, 242, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .04) 0, rgba(24, 119, 242, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .064) 0, rgba(24, 119, 242, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, .024) 0, rgba(255, 108, 92, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, .04) 0, rgba(255, 108, 92, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, .064) 0, rgba(255, 108, 92, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, .024) 0, rgba(245, 206, 51, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, .04) 0, rgba(245, 206, 51, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, .064) 0, rgba(245, 206, 51, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, .024) 0, rgba(37, 211, 102, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, .04) 0, rgba(37, 211, 102, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, .064) 0, rgba(37, 211, 102, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .024) 0, rgba(24, 119, 242, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .04) 0, rgba(24, 119, 242, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .064) 0, rgba(24, 119, 242, 0) 100%)
    background-size 156.45vw 99.03vw,156.45vw 99.03vw,156.45vw 99.03vw,226.86vw 145.44vw,226.86vw 145.44vw,226.86vw 145.44vw,171.96vw 110.31vw,171.96vw 110.31vw,171.96vw 110.31vw,130.29vw 83.58vw,130.29vw 83.58vw,130.29vw 83.58vw,198vw 126.9vw,198vw 126.9vw,198vw 126.9vw,300vw 192vw,300vw 192vw,300vw 192vw;
    margin 0
    background-attachment: fixed;
    background-position 55.97vw calc(((300vw - 100vh)/2 - 85.77vw) * -1),37.97vw calc(((300vw - 100vh)/2 - 85.77vw) * -1),37.97vw calc(((300vw - 100vh)/2 - 85.77vw) * -1),-100vw calc(((300vw - 100vh)/2 - 154.56vw) * -1),-100vw calc(((300vw - 100vh)/2 - 154.56vw) * -1),-100vw calc(((300vw - 100vh)/2 - 154.56vw) * -1),13.34vw calc(((300vw - 100vh)/2 - 53.88vw) * -1),13.34vw calc(((300vw - 100vh)/2 - 53.88vw) * -1),13.34vw calc(((300vw - 100vh)/2 - 53.88vw) * -1),10.64vw calc(((300vw - 100vh)/2 - 17.19vw) * -1),10.64vw calc(((300vw - 100vh)/2 - 17.19vw) * -1),10.64vw calc(((300vw - 100vh)/2 - 17.19vw) * -1),-49vw calc(((300vw - 100vh)/2 - 41.1vw) * -1),-49vw calc(((300vw - 100vh)/2 - 41.1vw) * -1),-49vw calc(((300vw - 100vh)/2 - 41.1vw) * -1),-100vw calc(((300vw - 100vh)/2 - 78vw) * -1),-100vw calc(((300vw - 100vh)/2 - 78vw) * -1),-100vw calc(((300vw - 100vh)/2 - 78vw) * -1)1
    .erp-w-content {
      display: flex
      align-items center
      justify-items center
      justify-content center
    }
  }
}
</style>
